<template>
    <div class="distinguish">
      <div class="main-content">
        <div class="main-center">
          <div class="search-dom">
            <div class="upload-wrapper">
              <el-upload
                ref="uploadImg"
                :limit="1"
                :before-upload="beforeUpload"
                :http-request="customUpload"
                action="#"
                drag
                accept=".jpg,.JPG,.jepg,.JEPG,.png,.PNG"
                class="upload-image"
              >
                <el-icon class="el-icon-upload"><UploadFilled /></el-icon>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div slot="tip" class="el-upload__tip">支持上传jpg、png格式，单个文件不能超过5mb</div>
              </el-upload>
              <el-progress v-show="percentage>0" :percentage="percentage" />
              <div class="btn-row">
                <!-- <el-button type="primary" class="query-btn" @click="queryMucedine">查询霉菌</el-button> -->
                <el-button type="warning" class="query-btn" @click="queryInsect">查询害虫</el-button>
              </div>
            </div>
          </div>
          <div :style="{'margin-left': marginL}" class="query-result" >
            <div class="query-result-content">
              <el-divider content-position="left">
                <img src="../../assets/siteImg/title.png" alt="" style="vertical-align: middle">
                <span style="vertical-align: middle">查询结果</span>
              </el-divider>
              <div v-loading="loading" element-loading-text="拼命加载中···" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.6)" class="result-image">
                <el-card
                  shadow="never"
                  :body-style="{ padding: '0px' }"
                >
                  <div class="image-box">
                    <el-image
                      :src="src"
                      :preview-src-list="[src]"
                      fit="contain"
                      class="re-image"
                    >
                      <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                      </div>
                    </el-image>
                  </div>
                </el-card>
                <div class="card-body">
                  <el-table
                    :data="tableData"
                    :header-cell-style="{background:'#4E86E7',color:'#fff'}"
                    tooltip-effect="light"
                    style="width: 100%;font-size: 16px;"
                  >
                    <el-table-column label="序号" align="center">
                      <template #default="scope">
                        <span>{{ scope.$index + 1 }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="name" label="中文名称" />
                    <el-table-column prop="enName" label="英文名称" />
                    <el-table-column prop="value" label="相似度">
                      <template #default="scope">
                        <span>{{ scope.row.value }}%</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="描述">
                      <template #default="scope">
                        <el-link v-if="scope.row.description" :href="scope.row.description" :underline="false" target="_blank">查看描述</el-link>
                      </template>
                    </el-table-column>
                    <el-table-column label="案例">
                      <template #default="scope">
                        <el-link v-if="scope.row.context"  :href="scope.row.context" :underline="false" target="_blank">查看案例</el-link>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { getMould, getPest , queryCase} from '@/api/toolsText.js'
  export default {
    name: 'Distinguish',
    data() {
      return {
        basePath: window.g.toolsCode,
        percentage: 0,
        loading: false,
        src: '',
        marginL: '100vw',
        tableData: [],
        curUploadImg: '',
      }
    },
    methods: {
      // 上传前
      beforeUpload(file) {
        const size5MB = file.size > 5 * 1024 * 1024 // 5MB
        if (size5MB) {
          this.$notify({
            title: '请重新上传小于5MB的图片',
            type: 'warning',
            duration: 2000
          })
          return false
        }
      },
      // 自定义上传
      customUpload(file) {
        this.curUploadImg = file.file
      },
      // 查询霉菌
      queryMucedine() {
        if (this.curUploadImg) {
          this.loading = true
          this.marginL = 0
          const imgFormData = new FormData()
          imgFormData.append('fileUrl', this.curUploadImg)
          const config = {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                var complete = ((progressEvent.loaded / progressEvent.total) * 100 | 0)
                this.percentage = complete
                if (this.percentage >= 100) {
                  this.percentage = 0
                }
              }
            }
          }
          getMould(imgFormData, config).then(res => {
            this.loading = false
            if (res.state === 200) {
              this.src = this.basePath + '/' + res.data.imgPath
              this.tableData = res.data.classifyProbability
              this.clearUpload()
            }
          }).catch(err => {
            this.loading = false
            this.$notify({
              message: err.response.data.message,
              type: 'warning',
              duration: 2000
            })
            this.clearUpload()
            this.src = ''
            this.tableData = []
          })
        } else {
          this.$notify({
            title: '请先上传图片',
            type: 'warning',
            duration: 2000
          })
        }
      },
      // 查询害虫
      queryInsect() {
        if (this.curUploadImg) {
          this.loading = true
          this.marginL = 0
          const imgFormData = new FormData()
          imgFormData.append('fileUrl', this.curUploadImg)
          const config = {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                var complete = ((progressEvent.loaded / progressEvent.total) * 100 | 0)
                this.percentage = complete
                if (this.percentage >= 100) {
                  this.percentage = 0
                }
              }
            }
          }
          getPest(imgFormData, config).then(res => {
            this.loading = false
            if (res.state === 200) {
              this.src = this.basePath + '/' +  res.data.imgPath
              let tableData = res.data.classifyProbability
              this.clearUpload()
              let name = tableData.map(r=>{
                return r.name
              }).join(',')
              queryCase({names:name,type:1}).then(r=>{
                let newTable = r.data
                this.tableData = tableData.map(el=>{
                  let obj = el
                  obj.description = newTable[el.name]?newTable[el.name].description:null
                  obj.context = newTable[el.name]?newTable[el.name].context:null
                  return obj
                })
                console.log(this.tableData)
              })
            }
          }).catch(err => {
            this.loading = false
            this.$notify({
              message: err.response.data.message,
              type: 'warning',
              duration: 2000
            })
            this.clearUpload()
            this.src = ''
            this.tableData = []
          })
        } else {
          this.$notify({
            title: '请先上传图片',
            type: 'warning',
            duration: 2000
          })
        }
      },
      clearUpload() {
        this.curUploadImg = ''
        this.$refs.uploadImg.clearFiles()
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .distinguish {
      background-color: white;
      overflow-x: hidden;
      .main-content {
        width: 100%;
        padding-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .main-center {
          width: 73.61%;
          .search-dom {
            width: 100%;
            margin-top: 20px;
            overflow: hidden;
            .upload-wrapper {
              margin: 100px 0;
              display: flex;
              border: 1px solid gray;
              padding: 20px 10px;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              .el-icon-upload {
                font-size: 130px;
                color: #9D9D9D;
              }
              .upload-image {
                width: 25.5%;
                .el-upload__text {
                  font-size: 16px;
                }
                .el-upload__tip {
                  font-size: 16px;
                }
              }
              .el-progress {
                width: 25.5%;
                margin-top: 20px;
              }
              .query-btn {
                font-size: 16px;
                margin-top: 30px;
                padding: 8px 20px;
              }
            }
  
          }
          .query-result {
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
            transition: all 1s ease;
            .query-result-content {
              background-color: #fff;
              padding: 20px;
              .el-divider {
                background-color: #134AAA;
              }
              .el-divider__text {
                color: #134AAA;
                padding: 0 10px 0 0px
              }
              .el-divider__text.is-left {
                font-size:16px;
                left: 0px;
              }
              .el-divider__text {
                font-size: 16px;
                font-weight: bold;
                font-style: italic;
              }
              .result-image {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                .el-card {
                  border: none;
                  .image-box {
                    min-width: 500px;
                    width: 100%;
                    height: 300px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .re-image {
                    max-width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    .el-image__error {
                      width: 500px;
                    }
                  }
                }
                .card-body {
                  width: 100%;
                  font-size: 20px;
                  font-weight: 600;
                  color: #134AAA;
                  padding: 30px 0;
                  .el-row {
                    height: 40px;
                    line-height: 40px;
                  }
                  .row-title {
                    color: #fff;
                    background-color: #4E86E7;
                  }
                  .el-row:nth-child(2n+1) {
  
                  }
                  .el-col {
                    text-align: center;
                  }
                }
              }
              >>>.el-icon-loading {
                font-size: 50px;
              }
              >>>.el-loading-text {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    /deep/.el-link{
     color: #232f97;
    font-size: 14px;
  }
  </style>
  